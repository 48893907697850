import React, {useState, createContext, useEffect} from "react";

import {REQUEST_PROCESSING_ERROR} from "../helpers/ErrorModals";
import handleDefaultExporter from "../helpers/defaultExporter";
import {LOAD_FAILED_ERROR_MESSAGE} from "../helpers/consts";

import API from "../helpers/api";


export const ReportsContext = createContext({});

export const ReportsProvider = handleDefaultExporter((props) => {

    const {user} = props;
    const [v3ReportsData, setV3ReportsData] = useState(null);

    useEffect(() => {
        if (user.uid) {
            handleReportsAccessRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.uid]);

    const handleReportsAccessRequest = () => {
        let getReportsAccessDataUrl = `${props.routes.reports_get_access_data}?uid=${parseInt(user.uid)}`;
        API.get(getReportsAccessDataUrl).then(resp => {
            const respData = resp.data;
            if (respData.error) {
                REQUEST_PROCESSING_ERROR(respData.message);
            } else {
                setV3ReportsData(respData.data);
            }
        }).catch(error => {
            const metaData = {
                error: error.toString(),
                api_url: getReportsAccessDataUrl,
                trigger: 'handleReportsAccessRequest',
                props
            };
            REQUEST_PROCESSING_ERROR(LOAD_FAILED_ERROR_MESSAGE, metaData);
        });
    }

    return (<ReportsContext.Provider
        value={{v3ReportsData}}>
        {props.children}
    </ReportsContext.Provider>);

});

