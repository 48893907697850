import createReducer from '../lib/createReducer';
import * as types from '../actions/types';

export const user = createReducer({
    username: null,
    uid: null,
    email: null,
    fullName: null,
    firstName: null,
    lastName: null,
    shortName: null,
    groups: [],
    isLoggedIn: false,
    passwordWasReset: null,
    accounts: [],
}, {

    [types.SET_IS_LOGGED_IN_STATUS](state, action) {
        let newState = Object.assign({}, state);
        newState.isLoggedIn = action.isLoggedIn;
        return newState;
    },

    // manage login
    [types.SET_LOGIN](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.username = action.data.username;
            newState.uid = action.data.id;
            newState.email = action.data.email;
            newState.firstName = action.data.first_name;
            newState.lastName = action.data.last_name;
            newState.shortName = action.data.short_name;
            newState.fullName = action.data.full_name;
            newState.groups = action.data.groups;
            newState.isLoggedIn = true;
            newState.passwordWasReset = action.data.password_was_reset;
            newState.accounts = action.data.accounts;
        } else {
            newState.username = null;
            newState.uid = null;
            newState.email = null;
            newState.fullName = null;
            newState.firstName = null;
            newState.lastName = null;
            newState.shortName = null;
            newState.groups = [];
            newState.isLoggedIn = false;
            newState.passwordWasReset = null;
            newState.accounts = [];
        }
        return newState;
    },

    [types.UPDATE_SELF](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.firstName = action.data.first_name;
            newState.lastName = action.data.last_name;
            newState.shortName = action.data.short_name;
            newState.fullName = action.data.full_name;
            newState.groups = action.data.groups;
            newState.passwordWasReset = action.data.password_was_reset;
            newState.accounts = action.data.accounts;
        }
        return newState;
    },

    [types.CHANGE_MY_DETAILS](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            if (action.data.fullName) {
                newState.fullName = action.data.fullName
            }
            if (action.data.email) {
                newState.email = action.data.email
            }
            if (action.data.groupID) {
                newState.groupID = action.data.groupID
            }
            if (action.data.password_was_reset !== undefined && action.data.password_was_reset !== null) {
                newState.passwordWasReset = action.data.password_was_reset;
            }
            if (action.data.accounts !== undefined && action.data.accounts !== null) {
                newState.accounts = action.data.accounts;
            }
        }
        return newState;
    },

    [types.LOGOUT](state, action) {
        let newState = Object.assign({}, state);
        newState.username = null;
        newState.uid = null;
        newState.email = null;
        newState.fullName = null;
        newState.firstName = null;
        newState.lastName = null;
        newState.shortName = null;
        newState.groups = [];
        newState.isLoggedIn = false;
        newState.passwordWasReset = null;
        newState.accounts = [];
        return newState;
    },
});
