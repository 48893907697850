import React, {memo} from 'react';
import {Row, Col} from 'antd';

import {ReactComponent as Ring} from '../../assets/imgs/rings.svg';

const LazyLoadingComponentPlaceholder = (props) => {
    return (<>
        {props.componentCSS ? <Col {...props.componentCSS}>
            <Ring/>
        </Col> : <Row>
            <Col span={24} align="middle">
                <Ring/>
            </Col>
        </Row>}
    </>);
};

export default memo(LazyLoadingComponentPlaceholder);
