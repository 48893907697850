import React from "react";

export const CSRF_COOKIE_NAME = "csrftoken";
export const WEEK_DAYS = [{value: "sunday", name: "Sunday"}, {value: "monday", name: "Monday"}, {
    value: "tuesday", name: "Tuesday"
}, {value: "wednesday", name: "Wednesday"}, {value: "thursday", name: "Thursday"}, {
    value: "friday", name: "Friday"
}, {value: "saturday", name: "Saturday"},];

export const HPAY_TIMEZONES = [
    {value: "US/Eastern", name: "Eastern Time"},
    {value: "US/Central", name: "Central Time"},
    {value: "US/Mountain", name: "Mountain Time"},
    {value: "US/Pacific", name: "Pacific Time"},
];

export const DEFAULT_HOURS_OFFERED = () => {
    let start = 0.00;
    let _list = [start];
    while (start !== 24.00) {
        start += 0.25;
        _list.push(start);
    }

    return _list;
};

export const WORK_DETAIL_WORK_TYPE = [{value: 1, name: "Hourly"}, {value: 2, name: "Piece"},];

export const LOAD_FAILED_ERROR_MESSAGE = 'Unable to fetch some details! Without it this page will not function correctly.\nPlease refresh the page or contact support.';

export const START_DAY_TIME_FORMAT = "HH:mm";

export const DATE_TIMEZONE_DIFFERENCE_WARNING = <p>
    <b>Note:</b> By default, dates and times in the date/time selector are displayed in your local timezone.
    However, the system will convert them to the timezone of the Account or Operation when displaying events
    and in reports. If your current timezone differs from the Account or Operation timezone,
    please select the date and time accordingly.
</p>;
