// USER ACTIONS
export const SET_IS_LOGGED_IN_STATUS = "SET_IS_LOGGED_IN_STATUS";
export const SET_LOGIN = "SET_LOGIN";
export const LOGOUT = "LOGOUT";
export const CHANGE_MY_DETAILS = "CHANGE_MY_DETAILS";
export const UPDATE_SELF = "UPDATE_SELF";


export function setIsLoggedInStatus(data) {
    return {
        type: SET_IS_LOGGED_IN_STATUS, data
    };
}

export function setLogin(data) {
    return {
        type: SET_LOGIN, data
    };
}

export function setLogout(data) {
    return {
        type: LOGOUT, data
    };
}

export function setChangeMyDetails(data) {
    return {
        type: CHANGE_MY_DETAILS, data
    };
}

export function setUpdateSelf(data) {
    return {
        type: UPDATE_SELF, data
    };
}
