import withRouter from "../lib/withRouterHelper";
import {connect} from "react-redux";
import {setLogin, setLogout} from "../actions/types";

function mapStateToProps({user, routes}) {
    const authenticated = user.username !== null;
    const passwordWasReset = user.passwordWasReset;
    return {
        user, routes, authenticated, passwordWasReset,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLoginAction: data => {
            dispatch(setLogin(data));
        }, setLogoutAction: data => {
            dispatch(setLogout(data));
        }
    };
}

const handleDefaultExporter = component => {
    return withRouter(connect(mapStateToProps, mapDispatchToProps)(component))
}

export default handleDefaultExporter;
