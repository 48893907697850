import React, {useState, createContext, useEffect} from "react";

import {REQUEST_PROCESSING_ERROR} from "../helpers/ErrorModals";
import handleDefaultExporter from "../helpers/defaultExporter";
import {LOAD_FAILED_ERROR_MESSAGE} from "../helpers/consts";
import API from "../helpers/api";

export const AccountsContext = createContext({});

export const AccountsProvider = handleDefaultExporter((props) => {
    const [accountsState, setAccountsState] = useState(null);
    const [operationData, setOperationData] = useState(0);
    const [checkedFor, setCheckedFor] = useState(0);

    const OPERATION_LS_KEY = "current_operation";

    useEffect(() => {
        if ((accountsState === undefined || accountsState === null) && (props.routes !== undefined && props.routes !== null) && props.authenticated) {
            fetchAccountsData();
        }

        if (getOperationLS() !== "null" && accountsState !== undefined && accountsState) {
            fetchOperationData(getOperationLS());
        }

        if (getOperationLS() === "null") {
            setOperationData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountsState, props.routes, props.authenticated]);

    const fetchAccountsData = (clearAccountsData = false) => {
        if (clearAccountsData) {
            setAccountsState(null);
            setCheckedFor(0);
            fetchAccountsData();
        } else {
            if (checkedFor <= 5) {
                setCheckedFor(checkedFor + 1);

                let getAccountsUrl = props.routes.entity_get_accounts;
                API.post(getAccountsUrl, {}).then(resp => {
                    if (!resp.data.error) {
                        setAccountsState(resp.data.data);
                    } else {
                        //call fetch again in  5 seconds
                        setTimeout(() => {
                            fetchAccountsData();
                        }, 5000);
                    }
                }).catch(error => {
                    REQUEST_PROCESSING_ERROR(LOAD_FAILED_ERROR_MESSAGE);
                    console.log(error);
                });
            }
        }
    };

    const fetchOperationData = (operationID) => {
        const operation_id = parseInt(operationID);
        const url = props.routes.entity_operation_access;
        if (operationID !== undefined && operationID !== null) {
            API.post(url, {operation_id}).then(resp => {
                if (resp.data.error) {
                    REQUEST_PROCESSING_ERROR(resp.data.message);
                } else {
                    setOperationData(resp.data.data);
                }
            }).catch(error => {
                const metaData = {error: error.toString(), api_url: url, props};
                REQUEST_PROCESSING_ERROR(LOAD_FAILED_ERROR_MESSAGE, metaData);
            });
        }
    }

    const setOperationLS = (operationID) => {
        localStorage.setItem(OPERATION_LS_KEY, operationID);

        if (getOperationLS() !== "null" && operationID !== null) {
            fetchOperationData(operationID);
        } else if (operationID === null) {
            setOperationData(null);
        }
    }

    const getOperationLS = () => {
        return localStorage.getItem(OPERATION_LS_KEY);
    }

    const removeOperationLS = () => {
        localStorage.removeItem(OPERATION_LS_KEY);
    }

    const resetAccountsContext = () => {
        setCheckedFor(0);
        setAccountsState(null);
        removeOperationLS();
    }

    return (<AccountsContext.Provider
        value={{
            resetAccountsContext,
            fetchAccountsData,
            setOperationLS,
            getOperationLS,
            setAccountsState,
            accountsState,
            operationData,
        }}>
        {props.children}
    </AccountsContext.Provider>);
})
