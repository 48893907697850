import {AccountsProvider} from "../contexts/AccountsContext";
import {ReportsProvider} from "../contexts/ReportsContext";

const HPayFunctionalComponent = (props) => {
    return (
        <AccountsProvider>
            <ReportsProvider>
                {props.children}
            </ReportsProvider>
        </AccountsProvider>
    );
}

export default HPayFunctionalComponent;
