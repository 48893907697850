import React, {useState, useEffect, useContext} from "react";
import {Layout, Menu, Row, Col, Divider} from 'antd';
import {
    AimOutlined,
    CopyOutlined,
    DiffOutlined,
    FormOutlined,
    HomeOutlined,
    PlusOutlined,
    SkinOutlined,
    TeamOutlined,
    ToolOutlined,
    UserOutlined,
    LogoutOutlined,
    TabletOutlined,
    ClusterOutlined,
    ControlOutlined,
    FileAddOutlined,
    ProfileOutlined,
    SettingOutlined,
    UserAddOutlined,
    BranchesOutlined,
    ContactsOutlined,
    SnippetsOutlined,
    SolutionOutlined,
    FileExcelOutlined,
    ExceptionOutlined,
    PartitionOutlined,
    FileSearchOutlined,
    UserSwitchOutlined,
    AppstoreAddOutlined,
    PullRequestOutlined,
    UsergroupAddOutlined,
    ReconciliationOutlined, BarcodeOutlined,
} from "@ant-design/icons";
import {Link} from 'react-router-dom';

import {REQUEST_PROCESSING_ERROR} from "../../helpers/ErrorModals";
import handleDefaultExporter from "../../helpers/defaultExporter";
import {LOAD_FAILED_ERROR_MESSAGE} from "../../helpers/consts";
import PATHS from "../../helpers/paths";
import Groups from "../../lib/Groups";
import API from "../../helpers/api";
import {ReportsContext} from "../../contexts/ReportsContext";

const {Sider} = Layout;

const SideNavigation = (props) => {
    const groupIDs = props.user.groups.map(elem => (elem.id));

    const {v3ReportsData} = useContext(ReportsContext);

    const [legacyReportsData, setLegacyReportsData] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1100);

    useEffect(() => {
        getUserActiveLegacyReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserActiveLegacyReports = () => {
        const url = props.routes.legacy_reports_get_user_active_reports;
        API.get(url).then(resp => {
            if (resp.data.error) {
                REQUEST_PROCESSING_ERROR(resp.data.message);
            } else {
                setLegacyReportsData(resp.data.data);
            }
        }).catch(error => {
            const metaData = {error: error.toString(), api_url: url, props};
            REQUEST_PROCESSING_ERROR(LOAD_FAILED_ERROR_MESSAGE, metaData);
        });
    }

    const onCollapse = (collapsed) => {
        setIsCollapsed(collapsed);
    };

    const MENU_ITEMS = [{
        label: (<Link className="grey-text text-darken-2 submenu" to={PATHS.DASHBOARD}>
            <HomeOutlined className="menu_icon"/><span>Home</span></Link>), key: PATHS.getKeyOfPath(PATHS.DASHBOARD)
    }, {
        label: (<Link className="grey-text text-darken-2 submenu" to={PATHS.LOGOUT}>
            <LogoutOutlined className="menu_icon"/><span>Logout</span></Link>), key: PATHS.getKeyOfPath(PATHS.LOGOUT)
    },]

    if (Groups.handlePermissions("hasAccessToUsers", groupIDs)) {
        const usersSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <UserOutlined className="menu_icon"/><span>Users</span></Link>),
            key: `${PATHS.getKeyOfSubMenu(PATHS.SYSTEM_ADD_USER)}`,
            children: []
        }

        if (Groups.handlePermissions("hasAccessToUsersAdd", groupIDs)) {
            const usersAddMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.SYSTEM_ADD_USER}>
                    <UserAddOutlined className="menu_icon"/><span>Add User</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.SYSTEM_ADD_USER)}`
            }
            usersSubMenu.children.push(usersAddMenu);
        }

        if (Groups.handlePermissions("hasAccessToUsersManage", groupIDs)) {
            const UsersManageMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.SYSTEM_MANAGE_USERS}>
                    <UserSwitchOutlined className="menu_icon"/><span>Manage Users</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.SYSTEM_VIEW_USER)}`
            }
            usersSubMenu.children.push(UsersManageMenu);
        }

        MENU_ITEMS.push(usersSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToCrews", groupIDs)) {
        const crewsSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <ContactsOutlined className="menu_icon"/><span>Crews</span></Link>),
            key: PATHS.getKeyOfSubMenu(PATHS.ENTITY_ADD_CREW),
            children: []
        }

        if (Groups.handlePermissions("hasAccessToCrewsAdd", groupIDs)) {
            const crewsAddMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_ADD_CREW}>
                    <UsergroupAddOutlined className="menu_icon"/><span>Add Crew</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.ENTITY_ADD_CREW),
            }
            crewsSubMenu.children.push(crewsAddMenu);
        }

        if (Groups.handlePermissions("hasAccessToCrewsManage", groupIDs)) {
            const crewsManageMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_MANAGE_CREWS}>
                    <TeamOutlined className="menu_icon"/><span>Manage Crews</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.ENTITY_MANAGE_CREWS),
            }
            crewsSubMenu.children.push(crewsManageMenu);
        }

        if (Groups.handlePermissions("hasAccessToCrewsBulkManagement", groupIDs)) {
            const crewsBulkManagementMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_MANAGE_CREWS_BULK}>
                    <PartitionOutlined className="menu_icon"/><span>Bulk Management</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.ENTITY_MANAGE_CREWS_BULK),
            }
            crewsSubMenu.children.push(crewsBulkManagementMenu);
        }

        MENU_ITEMS.push(crewsSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToDevices", groupIDs)) {
        const devicesSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <TabletOutlined className="menu_icon"/><span>Devices</span></Link>),
            key: PATHS.getKeyOfSubMenu(PATHS.ENTITY_ADD_DEVICE_SETTINGS),
            children: []
        }

        if(Groups.handlePermissions("hasAccessToDeviceAdd", groupIDs)) {
            const addDeviceMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_DEVICE_ADD}>
                    <PlusOutlined className="menu_icon"/><span>Add Device</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.ENTITY_DEVICE_ADD),
            }
            devicesSubMenu.children.push(addDeviceMenu);
        }

        if(Groups.handlePermissions("hasAccessToDeviceManage", groupIDs)) {
            const manageDeviceMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_DEVICE_MANAGE}>
                    <TabletOutlined className="menu_icon"/><span>Manage Devices</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.ENTITY_DEVICE_MANAGE),
            }
            devicesSubMenu.children.push(manageDeviceMenu);
        }

        if(Groups.handlePermissions("hasAccessToDeviceAddSettings", groupIDs)) {
            const addSettingsMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_ADD_DEVICE_SETTINGS}>
                    <AppstoreAddOutlined className="menu_icon"/><span>Add Settings</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.ENTITY_ADD_DEVICE_SETTINGS),
            }
            devicesSubMenu.children.push(addSettingsMenu);
        }

        if(Groups.handlePermissions("hasAccessToDeviceViewConfigProfile", groupIDs)) {
            const viewConfigProfilesMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES}>
                    <SettingOutlined className="menu_icon"/><span>View Config Profiles</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES),
            }
            devicesSubMenu.children.push(viewConfigProfilesMenu);
        }

        // OLD DEVICES PAGES
        // const viewDevicesMenu = {
        //     label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_VIEW_DEVICES}>
        //         <TabletOutlined className="menu_icon"/>
        //         <span>View Devices Old</span>
        //     </Link>), key: `${PATHS.getKeyOfPath(PATHS.ENTITY_VIEW_DEVICES)}`
        // }
        // manageDevicesSubMenu.children.push(viewDevicesMenu);
        // const addDevicesMenu = {
        //     label: (<Link className="grey-text text-darken-2" to={PATHS.ENTITY_ADD_DEVICES}>
        //         <ClusterOutlined className="menu_icon"/>
        //         <span>Add Devices</span>
        //     </Link>), key: `${PATHS.getKeyOfPath(PATHS.ENTITY_ADD_DEVICES)}`
        // }
        // manageDevicesSubMenu.children.push(addDevicesMenu);

        MENU_ITEMS.push(devicesSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToWorkers", groupIDs)) {
        const workersSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <SkinOutlined className="menu_icon"/><span>Workers</span></Link>),
            key: PATHS.getKeyOfSubMenu(PATHS.HPAY_CORE_WORKERS_ADD),
            children: []
        }

        if (Groups.handlePermissions("hasAccessToWorkersAdd", groupIDs)) {
            const workersAddMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_WORKERS_ADD}>
                    <UserAddOutlined className="menu_icon"/><span>Add Worker</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.HPAY_CORE_WORKERS_ADD),
            }
            workersSubMenu.children.push(workersAddMenu);
        }

        if (Groups.handlePermissions("hasAccessToWorkersManage", groupIDs)) {
            const workersManageMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_WORKERS_MANAGE}>
                    <UserSwitchOutlined className="menu_icon"/><span>Manage Workers</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.HPAY_CORE_WORKERS_MANAGE),
            }
            workersSubMenu.children.push(workersManageMenu);
        }

        if (Groups.handlePermissions("hasAccessToWorkersBulkOperations", groupIDs)) {
            const workersBulkManagementMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_WORKERS_BULK_MANAGEMENT}>
                    <PartitionOutlined className="menu_icon"/><span>Bulk Management</span></Link>),
                key: PATHS.getKeyOfPath(PATHS.HPAY_CORE_WORKERS_BULK_MANAGEMENT),
            }
            workersSubMenu.children.push(workersBulkManagementMenu);
        }

        MENU_ITEMS.push(workersSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToContracts", groupIDs)) {
        const contractsSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <SolutionOutlined className="menu_icon"/><span>Contracts</span></Link>),
            key: PATHS.getKeyOfSubMenu(PATHS.HPAY_CORE_CONTRACTS_ADD),
            children: []
        }

        if (Groups.handlePermissions("hasAccessToContractsAdd", groupIDs)) {
            const contractsAddMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_CONTRACTS_ADD}>
                    <FormOutlined className="menu_icon"/><span>Add Contract</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_CONTRACTS_ADD)}`,
            }
            contractsSubMenu.children.push(contractsAddMenu);
        }

        if (Groups.handlePermissions("hasAccessToContractsManage", groupIDs)) {
            const contractsManageMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_CONTRACTS_MANAGE}>
                    <SnippetsOutlined className="menu_icon"/><span>Manage Contracts</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_CONTRACTS_MANAGE)}`,
            }
            contractsSubMenu.children.push(contractsManageMenu);
        }

        MENU_ITEMS.push(contractsSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToH2AExceptions", groupIDs)) {
        const h2aExceptionsSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <ExceptionOutlined className="menu_icon"/><span>H-2A Exceptions</span></Link>),
            key: `${PATHS.getKeyOfSubMenu(PATHS.HPAY_CORE_H2A_EXCEPTIONS_ADD)}`,
            children: []
        }

        if (Groups.handlePermissions("hasAccessToH2AExceptionsAdd", groupIDs)) {
            const h2aExceptionsAddMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_H2A_EXCEPTIONS_ADD}>
                    <DiffOutlined className="menu_icon"/><span>Add Exception</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_H2A_EXCEPTIONS_ADD)}`,
            }
            h2aExceptionsSubMenu.children.push(h2aExceptionsAddMenu);
        }

        if (Groups.handlePermissions("hasAccessToH2AExceptionsManage", groupIDs)) {
            const h2aExceptionsManageMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_H2A_EXCEPTIONS_MANAGE}>
                    <CopyOutlined className="menu_icon"/><span>Manage Exceptions</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_H2A_EXCEPTIONS_MANAGE)}`,
            }
            h2aExceptionsSubMenu.children.push(h2aExceptionsManageMenu);
        }

        MENU_ITEMS.push(h2aExceptionsSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToWorkDetails", groupIDs)) {
        const workDetailsSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <ReconciliationOutlined className="menu_icon"/><span>Work Details</span></Link>),
            key: `${PATHS.getKeyOfSubMenu(PATHS.HPAY_CORE_WORK_DETAIL_ADD)}`,
            children: []
        }

        if (Groups.handlePermissions("hasAccessToWorkDetailsAdd", groupIDs)) {
            const workDetailsAddMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_WORK_DETAIL_ADD}>
                    <FileAddOutlined className="menu_icon"/><span>Add Work Detail</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_WORK_DETAIL_ADD)}`,
            }
            workDetailsSubMenu.children.push(workDetailsAddMenu);
        }

        if (Groups.handlePermissions("hasAccessToWorkDetailsManage", groupIDs)) {
            const workDetailsManageMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_WORK_DETAIL_VIEW}>
                    <FileSearchOutlined className="menu_icon"/><span>Manage Work Details</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_WORK_DETAIL_VIEW)}`,
            }
            workDetailsSubMenu.children.push(workDetailsManageMenu);
        }

        if (Groups.handlePermissions("hasAccessToAliasesManage", groupIDs)) {
            const aliasesManageMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_WORK_DETAIL_MANAGE_ALIASES}>
                    <ControlOutlined className="menu_icon"/><span>Manage Aliases</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_WORK_DETAIL_MANAGE_ALIASES)}`,
            }
            workDetailsSubMenu.children.push(aliasesManageMenu);
        }

        if (Groups.handlePermissions("hasAccessToWorkDetailsBulkOperations", groupIDs)) {
            const bulkOperationsMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.HPAY_CORE_WORK_DETAIL_BULK_OPERATIONS}>
                    <PartitionOutlined className="menu_icon"/><span>Bulk Operations</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.HPAY_CORE_WORK_DETAIL_BULK_OPERATIONS)}`,
            }
            workDetailsSubMenu.children.push(bulkOperationsMenu);
        }

        MENU_ITEMS.push(workDetailsSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToTools", groupIDs)) {
        const toolsSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <ToolOutlined className="menu_icon"/><span>Tools</span></Link>),
            key: `${PATHS.getKeyOfSubMenu(PATHS.TOOL_V3_MIGRATION)}`,
            children: []
        }

        if (Groups.handlePermissions("hasAccessToAccessManager", groupIDs)) {
            const accessManagerMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.TOOL_ACCESS_MANAGER}>
                    <PullRequestOutlined className="menu_icon"/><span>Access Manager</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.TOOL_ACCESS_MANAGER)}`,
            }
            toolsSubMenu.children.push(accessManagerMenu);
        }

        if (Groups.handlePermissions("hasAccessToTimeSheetManager", groupIDs)) {
            const timeSheetManagerMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.TOOL_TIME_SHEET_MANAGER}>
                    <ClusterOutlined className="menu_icon"/><span>Time Sheet Manager</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.TOOL_TIME_SHEET_MANAGER)}`,
            }
            toolsSubMenu.children.push(timeSheetManagerMenu);
        }

        if (Groups.handlePermissions("hasAccessToH2AExceptionsManager", groupIDs)) {
            const h2aExceptionsManagerMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.TOOL_H2A_EXCEPTIONS_MANAGER}>
                    <ClusterOutlined className="menu_icon"/><span>H2A Exc Manager</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.TOOL_H2A_EXCEPTIONS_MANAGER)}`,
            }
            toolsSubMenu.children.push(h2aExceptionsManagerMenu);
        }

        if (Groups.handlePermissions("hasAccessToV3Migration", groupIDs)) {
            const v3MigrationMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.TOOL_V3_MIGRATION}>
                    <BranchesOutlined className="menu_icon"/><span>V3 Migration</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.TOOL_V3_MIGRATION)}`,
            }
            toolsSubMenu.children.push(v3MigrationMenu);
        }

        if (Groups.handlePermissions("hasAccessToBBCardSetup", groupIDs)) {
            const bbCardSetup = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.TOOL_BLACK_BOX_CARD_SETUP}>
                    <BarcodeOutlined className="menu_icon"/><span>BlackBox Card Setup</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.TOOL_BLACK_BOX_CARD_SETUP)}`,
            }
            toolsSubMenu.children.push(bbCardSetup);
        }

        MENU_ITEMS.push(toolsSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToReports", groupIDs)) {
        const reportsSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to={null}>
                <FileExcelOutlined className="menu_icon"/><span>Reports</span></Link>),
            key: `${PATHS.getKeyOfSubMenu(PATHS.REPORTS)}`,
            children: []
        }

        if (Groups.handlePermissions("hasAccessReportsManagement", groupIDs)) {
            const manageIWLMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.REPORTS_MANAGEMENT}>
                    <AimOutlined className="menu_icon"/><span>Manage</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.REPORTS_MANAGEMENT)}`,
            }
            reportsSubMenu.children.push(manageIWLMenu);
        }

        if (v3ReportsData !== null) {
            const activeReportsKeys = Object.keys(v3ReportsData.active_reports).sort();
            const availableReports = {};
            v3ReportsData.available_reports.map(r => {
                availableReports[r.key] = r.name;
                return true;
            });
            activeReportsKeys.map(key => {
                if (availableReports[key] !== undefined && availableReports[key] !== null) {
                    reportsSubMenu.children.push({
                        label: (<Link className="grey-text text-darken-2"
                                      to={PATHS.generateDynamicPath(PATHS.DYNAMIC_V3_REPORTS_REPORT, [key])}>
                            <ProfileOutlined className="menu_icon"/><span>{availableReports[key]}</span></Link>),
                        key: `${PATHS.getKeyOfPath(PATHS.REPORTS)}-${key}`
                    });
                }
                return true;
            });
        }


        MENU_ITEMS.push(reportsSubMenu);
    }

    if (Groups.handlePermissions("hasAccessToLegacyReports", groupIDs)) {
        const legacyReportsSubMenu = {
            label: (<Link className="grey-text text-darken-2 submenu" to="#">
                <FileExcelOutlined className="menu_icon"/><span>Legacy Reports</span></Link>),
            key: `${PATHS.getKeyOfSubMenu(PATHS.LEGACY_REPORTS_REPORT)}`,
            children: []
        }

        if (Groups.handlePermissions("hasAccessToLegacyReportsManagement", groupIDs)) {
            const manageUserAccessMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.LEGACY_REPORTS_MANAGE_USER_ACCESS}>
                    <AimOutlined className="menu_icon"/><span>Manage User Access</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.LEGACY_REPORTS_MANAGE_USER_ACCESS)}`,
            }
            legacyReportsSubMenu.children.push(manageUserAccessMenu);

            const manageSupervisorsMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.LEGACY_REPORTS_MANAGE_SUPERVISORS}>
                    <AimOutlined className="menu_icon"/><span>Manage Supervisors</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.LEGACY_REPORTS_MANAGE_SUPERVISORS)}`,
            }
            legacyReportsSubMenu.children.push(manageSupervisorsMenu);

            const manageWorkDetailsAccessMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.LEGACY_REPORTS_MANAGE_WORK_DETAILS_ACCESS}>
                    <AimOutlined className="menu_icon"/><span style={{whiteSpace: "normal"}}>Work Details Access</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.LEGACY_REPORTS_MANAGE_WORK_DETAILS_ACCESS)}`,
            }
            legacyReportsSubMenu.children.push(manageWorkDetailsAccessMenu);
        }

        if (Groups.handlePermissions("hasAccessToManageIWL", groupIDs)) {
            const manageIWLMenu = {
                label: (<Link className="grey-text text-darken-2" to={PATHS.LEGACY_REPORTS_SUPERVISOR_MANAGE_IWL}>
                    <ProfileOutlined className="menu_icon"/><span>Manage IWL</span></Link>),
                key: `${PATHS.getKeyOfPath(PATHS.LEGACY_REPORTS_SUPERVISOR_MANAGE_IWL)}`,
            }
            legacyReportsSubMenu.children.push(manageIWLMenu);
        }

        if (Groups.handlePermissions("hasAccessToLegacyReports", groupIDs) && legacyReportsData !== null) {
            legacyReportsData.reports.map(r => {
                legacyReportsSubMenu.children.push({
                    label: (<Link className="grey-text text-darken-2"
                                  to={PATHS.generateDynamicPath(PATHS.DYNAMIC_LEGACY_REPORTS_REPORT, [r.key])}>
                        <ProfileOutlined className="menu_icon"/><span>{r.name}</span></Link>),
                    key: `${PATHS.getKeyOfPath(PATHS.LEGACY_REPORTS_MANAGE_USER_ACCESS)}-${r.id}`
                });
                return true;
            });
        }

        MENU_ITEMS.push(legacyReportsSubMenu);
    }

    return (<Sider theme="light" collapsible collapsed={isCollapsed} onCollapse={onCollapse}
                   width="250" collapsedWidth={0}>

        {!isCollapsed && <Row className="user_info">
            <Col span={24}>
                <Row className="user_data">{props.user.fullName}</Row>
                <Row className="user_data">{props.user.username}</Row>
                {props.user.email && <Row className="user_data">{props.user.email}</Row>}
            </Col>

            <Col span={24}><Divider/></Col>
        </Row>}

        <Menu theme="light" mode="inline" className="menu_overwrite" items={MENU_ITEMS}
              style={{overflow: 'auto', height: window.innerHeight * 0.777}}/>
    </Sider>);
}

export default handleDefaultExporter(SideNavigation);
