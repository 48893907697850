import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router';

import PATHS from "../helpers/paths";
import handleDefaultExporter from "../helpers/defaultExporter";

const PrivateRoute = (props) => {
    const {authenticated, passwordWasReset} = props;
    const location = useLocation();
    const pathname = window.location.pathname.slice(1);

    return (authenticated ? <>
        {(passwordWasReset && pathname !== PATHS.PASSWORD_RESET) ?
            <Navigate to={{pathname: PATHS.PASSWORD_RESET}}/> : <>
                {(!passwordWasReset && pathname === PATHS.PASSWORD_RESET) ?
                    <Navigate to={{pathname: PATHS.DASHBOARD}}/> : <Outlet/>}
            </>}
    </> : <Navigate to={{pathname: PATHS.LOGIN, state: {from: location}}}/>);
}

export default handleDefaultExporter(PrivateRoute);