import React from 'react';
import App from './App';
import {Provider} from 'react-redux';
import bugsnagClient from 'lib/bugsnag';
import {store, persistor} from './store';
import * as serviceWorker from './serviceWorker';
import * as ReactDOMClient from 'react-dom/client';
import {PersistGate} from 'redux-persist/integration/react';
import 'dayjs/locale/en';
import dayjs from "dayjs";

dayjs.locale('en');

const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);
root.render(<ErrorBoundary>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>
</ErrorBoundary>);

serviceWorker.unregister();
