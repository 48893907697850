import {Modal} from "antd";
import bugsnagClient from "../lib/bugsnag";


export const REQUEST_PROCESSING_ERROR = (message = null, metaData = null, failSilently=false) => {

    const bugsnagEventHandler = (event) => {
        const {user} = metaData.props;
        const _metaData = {
            error: metaData.error,
            api_url: metaData.api_url,
            username: user.username,
            location: window.location.pathname,
        }
        event.setUser(user.uid, user.email, user.username);
        event.addMetadata("data", _metaData);
    }

    if (metaData !== undefined && metaData !== null) {
        bugsnagClient.notify(new Error(metaData.error), bugsnagEventHandler);
    }

    let text = "Something went wrong! Please try again or contact support.";
    if (message !== undefined && message !== null) {
        if (Array.isArray(message)) {
            text = <ul>{message.map(msg => <li key={new Date().getTime() - Math.random()}>{msg}</li>)}</ul>;
        } else {
            text = message;
        }
    }

    if(failSilently !== undefined && !failSilently){
        Modal.error({
            title: 'Error', content: text, okButtonProps: {type: "primary"}
        });
    }
};
